const commonGlobal = {
  methods: {
    getImage(item) {
      // compute image url from object
      let url = '#';
      if (item && item.url) url = item.url;
      return url;
    },
    getImageSet(item) {
      // compute images srcset from object
      let srcset = '';
      if (item && 'sizes' in item) {
        srcset = this.$arrToSrcset(item.sizes);
      }
      return srcset;
    },
  },
};

export default commonGlobal;
